import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeaderAbout from "../components/PageHeaderAbout";
import AboutOne from "../components/AboutOne";
import Footer from "../components/Footer";
import Mission from "../components/Mission";
import VideoOne from "../components/VideoOne";
import Inquiry from "../components/Inquiry";
import Learninig from '../components/Learninig';
import Topbar from "../components/Topbar";

const AboutOriPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | About">
            <Topbar />
            <NavOne />
            <PageHeaderAbout title="About" />
            <AboutOne />
            <Mission />
            <VideoOne />
            <Learninig />
            <Inquiry />
            <Footer />
        </Layout>
    );
};

export default AboutOriPage;

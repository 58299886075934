import React,{ useEffect, useState } from "react";
import { Formik, Form } from "formik";
import http from '../_api/http';
// import { useToast, immediateToast } from 'izitoast-react'

const Inquiry = () => {
  
  const [values,setValues] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    message: "",
  })
  
  const [validations, setValidations] = useState({
    fullname: "",
    email: "",
    phone_number: "",
    message: "",
  });
 
  const validateAll = () => {
    const { fullname, email, phone_number,message } = values
    const validations = { fullname: '', email:'', phone_number: '', message: '' }
    let isValid = true ;
    
    if (!fullname) {
      validations.fullname = 'Fullname is required'
      isValid = false
    }
    
    if (!email) {
      validations.email = 'Email is required'
      isValid = false
    }
    
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = 'Email format must be as example@mail.com'
      isValid = false
    }
    
    if (!phone_number) {
      validations.phone_number = 'Phone Number is required'
      isValid = false
    }

    // if (phone_number && phone_number.length < 10 || phone_number.length > 10) {
    //   validations.phone_number = 'Phone Number must contain 10 digits'
    //   isValid = false
    // }

    if (!message) {
      validations.message = 'Detail is required'
      isValid = false
    }
    
    
    if (!isValid) {
      setValidations(validations);
    }
    
    return isValid ;
  }

  const clearData = () => {
    setValues({
      fullname: "",
      email: "",
      phone_number: "",
      message: "",
    });
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target ;
    setValues({...values, [name]: value });
  }

  // useEffect(() => {
  //   immediateToast('info', {
  //     message: 'Hi, how it is going'
  //   })
  // });
  
  // const showTost = (msg, theme) => {
  //   theme = theme || "info";
  //   immediateToast(theme, {
  //     message: msg,
  //     timeout: 3000
  //   });
  // }

  const handleSubmit = (e) => {
    // debugger
    e.preventDefault();

    const isValid = validateAll() ;
    
    if (!isValid) {
      return false ;
    }
    http.post('api/inquire/store',values).then((res)=>{
      // immediateToast('info', {
      //   message: 'Successfully Submitting Your Inquiry Details!',
      //   timeout: 3000
      // });
  
    // showTost("Successfully Submitting Your Inquiry Details!");
    clearData();
    // window.location.reload(false);
    })
  }

    const { fullname, email, phone_number,message } = values ;
    
    const validateOne = (e) => {
      const { name } = e.target ;
      const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      const value = values[name] ;
      let message = '' ;
      
      if (!value) {
        message = `${name} is required`
      }
      
      // if (value && name === 'phone_number' && !/[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)) {
      //   message = 'Phone Number must contain 10 number digits'
      // }

      if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
        message = 'Email format must be as example@mail.com'
      }
      
      setValidations({...validations, [name]: message });
    }
    const { 
      fullname: fullnameVal, 
      email: emailVal, 
      phone_number: numberVal,
      message: messageVal,
    } = validations ;
  
  return (
    <section className="countdown-one mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="countdown-one__content">
              <h2 className="countdown-one__title">Inquire Now </h2>
              {/* <p className="countdown-one__tag-line">
                get premium online courses for free!
              </p> */}
              <p className="countdown-one__text">
              If you have any unanswered questions or are in some sort of confusion, please fill in the details.<br/> Someone
              from Shanti Asiatic School will get in touch with you.<br/>
              You may also email us at info@surat.shantiasiatic.com
              </p>
            </div>
          </div>
          
          <div className="col-lg-6">
            <div className="become-teacher__form">
           
              <div className="become-teacher__form-top">
                <h2 className="become-teacher__form-title">Inquire Now</h2>
              </div>
              <Formik>
              <Form id='contactform'
               onSubmit={handleSubmit}
                onBlur={validateOne}
                className="become-teacher__form-content contact-form-validated">
                <input type="text" placeholder="Your Name" name="fullname" autoComplete="off" value={fullname} onChange={handleChange}  />
                <div className='error'>{fullnameVal}</div>
                <input type="email" placeholder="Email Address" name="email" autoComplete="off" value={email} onChange={handleChange}   />
                <div className='error'>{emailVal}</div>
                <input type="number" placeholder="Phone Number" name="phone_number" autoComplete="off" value={phone_number} onChange={handleChange} />
                <div className='error'>{numberVal}</div>
                <input type="text" placeholder="Comment" name="message" autoComplete="off" value={message} onChange={handleChange}  />
                <div className='error'>{messageVal}</div>
                <button type="submit"
                  className="thm-btn become-teacher__form-btn"
                >
                  Apply For It
                </button>
              </Form>
              </Formik>
              <div className="result text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Inquiry;

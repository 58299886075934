import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ClientStripe from "../assets/images/circle-stripe.png";
import aboutImage1 from "../assets/images/About1.png";
import aboutImage2 from "../assets/images/About2.png";

class AboutOne extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="about-one ">
        <img src={ClientStripe} className="about-one__circle" alt="" />
        <div className="container text-center">
          <div className="block-title text-center">
            <h2 className="block-title__title title">
              An institution that nurtures talent, promotes growth and builds a character
            </h2>
          </div>
          <div className="about-one__img">
            <div className="row">
              <div className="col-lg-6">
                <img src={aboutImage1} alt="" />
              </div>
              <div className="col-lg-6">
                <img src={aboutImage2} alt="" />
              </div>
            </div>
            <div className="about-one__review" style={{display:"none"}}>
              <p className="about-one__review-count counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp end={this.state.startCounter ? 88750 : 0} />
                </VisibilitySensor>
              </p>
              <div className="about-one__review-stars">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </div>
              <p className="about-one__review-text">students loved us</p>
            </div>
          </div>
          <p className="about-one__text text-left">
            Getting enrollment into a good school is the beginning of a bright future. Shanti Asiatic School
            was formed in the year 2012 with a vision to see every child become independent, productive and
            responsible, ready to transform the world into a better living place. Shanti Asiatic School is
            managed by Milestone Educom Trust (Chiripal Group). The campus developed in over 6 Acres of
            land in the lap of nature, with excellent academic staff and CBSE curriculum combined with co-
            curricular activities & sports. <br /> SAS aims to provide a holistic experience and all-round
            development of a child; enabling him to cope with the ever-growing challenges of competitive world.
            SAS Surat provides contemporary and inventive education in an inspiring and pleasant atmosphere.
          </p>
          <a href="#none" className="thm-btn about-one__btn">
            Start Learning Now
          </a>
        </div>
      </section>
    );
  }
}

export default AboutOne;

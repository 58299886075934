import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// import fact1 from "../assets/images/fact-1-1.jpg";
import fact2 from "../assets/images/CoreValues.png";
import line2 from "../assets/images/line-stripe-2.png";
class Learninig extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false
    };
  }
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="cta-six thm-gray-bg">
        {/* <img src={line2} className="cta-six__line" alt="" /> */}
        <div className="container-fluid clearfix">
          <div className="cta-six__left">
            <div className="cta-six__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  {/* Start online learning anything */}Core Values
                </h2>
              </div>
              <p className="">
              At Shanti Asiatic School, we believe in instilling core values such as to develop a lifelong pursuit
                of knowledge, understanding wisdom, to be healthy, confident and resilient, to accept
                responsibility and act ethically and to face adversity with intelligence. They are been taught to
                know and respect themselves, others and the natural environment, to the community and to the
                world in which we all live.

              </p>
            </div>
          </div>
          <div className="cta-six__right">
            <img src={fact2} alt="" />
            <h2 className="cta-six__title">
            We are a family of over{" "}
              <span className="counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  delayedCall
                >
                  <CountUp end={this.state.startCounter ? 7000 : 0} />
                </VisibilitySensor>
              </span>{" "}
              students and teachers
            </h2>
          </div>
        </div>
      </section>
    );
  }
}

export default Learninig;

import React from 'react';

const Mission = () => {
    return (
        <section className="faq-one">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span><em className='fa fa-eye'></em></span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">Vision</h2>
                                <p className="faq-one__text">To Mould young minds into community leader.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span><em className='fa fa-bullhorn'></em></span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">Mission</h2>
                                <p className="faq-one__text">To create a joyful school environment that will generate happy, righteous & quality individuals equipped to make right choices in life & grow up to be responsible citizens of the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Mission;
